/* line 1, ../assets/sass/page-loggedout.scss */
.panel-heading .panel-title a:after {
  /* symbol for "opening" panels */
  font-family: 'Glyphicons Halflings';
  /* essential for enabling glyphicon */
  content: "\e114";
  /* adjust as needed, taken from bootstrap.css */
  float: right;
  /* adjust as needed */
  color: grey;
  /* adjust as needed */
}

/* line 8, ../assets/sass/page-loggedout.scss */
.panel-heading .panel-title a.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\e080";
  /* adjust as needed, taken from bootstrap.css */
}

/* Sidebar List Item */
/* line 14, ../assets/sass/page-loggedout.scss */
.sidebarSelectDepartmentOrIndustry .list-group-item.sidebarItemBgGreen {
  color: #fff;
  background-color: #449d44;
}

/* Popup Modal Login Form */
/* line 20, ../assets/sass/page-loggedout.scss */
.modalLogin .containerSignInWithCAS {
  margin-top: 5px;
}

/* line 24, ../assets/sass/page-loggedout.scss */
.modalLogin .btnSignInCAS {
  text-align: left;
}

/*# sourceMappingURL=page-loggedout.css.map */
